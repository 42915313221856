<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item
                :to="{ path: '/system_Management/system_management_home' }"
                >系统管理</el-breadcrumb-item
            >
            <el-breadcrumb-item>轮播图信息</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <!-- <el-card style="margin-top: 15px">
          <el-row class="title_row">
              <el-col>
                  <span class="title_class">日志筛选</span>
              </el-col>
          </el-row>
          <el-form
              :model="queryForm"
              class="filter_Form"
              label-width="120px"
              ref="queryFormRef"
          >
              <el-row :gutter="10">
                  <el-col :span="6">
                      <el-form-item label="创建人" prop="userName">
                          <el-input
                              v-model="queryForm.userName"
                              placeholder="请输入"
                              clearable
                          ></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6">
                      <el-form-item label="请求地址" prop="url">
                          <el-input
                              v-model="queryForm.url"
                              placeholder="请输入"
                              clearable
                          ></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6">
                      <el-form-item label="终端" prop="terminal">
                          <el-select
                              placeholder="请选择"
                              style="width: 100%"
                              v-model="queryForm.terminal"
                              clearable
                          >
                              <el-option
                                  v-for="item in dict_terminal"
                                  :key="item.dictCode"
                                  :label="item.dictLabel"
                                  :value="item.dictValue"
                              ></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="起止时间" prop="dateTimeRange">
            <el-date-picker
            style="width: 100%;"
              v-model="queryForm.dateTimeRange"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
              clearable
            >
            </el-date-picker>
          </el-form-item>
                  </el-col>
              </el-row>
              <el-row :gutter="20" type="flex" justify="end">
                  <el-col :span="2">
                      <el-button
                          type="primary"
                          style="width: 100%"
                          @click="getAllLogsList('search')"
                          >查询</el-button
                      >
                  </el-col>
                  <el-col :span="2">
                      <el-button
                          type="primary"
                          plain
                          style="width: 100%"
                          @click="reset"
                          >重置</el-button
                      >
                  </el-col>
              </el-row>
          </el-form>
      </el-card> -->
        <!-- 日志列表 -->
        <el-card class="list_card" style="margin-top: 20px">
            <el-row class="title_row">
                <el-col>
                    <span>轮播图列表</span>
                    <el-button
                        type="primary"
                        size="mini"
                        icon="el-icon-circle-plus-outline"
                        style="margin-left: 20px"
                        @click="addContactDialogVisible = true"
                        >新增</el-button
                    >
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column label="排序" prop="sort" width="40px"></el-table-column>
                <el-table-column
                    label="图片地址"
                    prop="picUrl"
                ></el-table-column>
                <el-table-column label="banner图" prop="picUrl">
                    <template slot-scope="scope" v-if="scope.row.picUrl">
                        <el-image
                            v-if="scope.row.picUrl"
                            style="width: 120px; height: 60px"
                            :src="scope.row.picUrl"
                            fit="contain"
                            :preview-src-list="[scope.row.picUrl]"
                        ></el-image>
                        <div v-else>-</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="跳转链接"
                    prop="linkUrl"
                ></el-table-column>
                <el-table-column label="flag" prop="flag"></el-table-column>
                <el-table-column label="type" prop="type"></el-table-column>
                <el-table-column label="签到状态">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.status"
                            >已启用</el-tag
                        >
                        <el-tag type="warning" v-else>未启用</el-tag>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="状态">
                    <template slot-scope="scope">
                        <el-switch
                            :active-value="0"
                            :inactive-value="1"
                            v-model="scope.row.isDelete"
                            @change="accountStateChange(scope.row)"
                        ></el-switch>
                    </template>
                </el-table-column> -->
                <el-table-column label="备注" prop="remark"></el-table-column>
                <el-table-column label="操作" width="120px">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            size="mini"
                            style="color: rgb(245, 108, 108)"
                            @click="showDeleteAccountTip(scope.row.id)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
            <!-- 新增联系人信息dialog -->
    <el-dialog
      title="新增banner"
      :visible.sync="addContactDialogVisible"
      width="30%"
      @close="addContactDialogClose"
    >
      <el-form
        :model="addContactForm"
        ref="addContactFormRef"
        :rules="addContactFormRules"
        label-width="80px"
      >
        <el-form-item label="flag" prop="flag">
          <el-input
            placeholder="请输入"
            v-model="addContactForm.flag"
          ></el-input>
        </el-form-item>
        <el-form-item label="跳转地址" prop="linkUrl">
          <el-input
            placeholder="请输入"
            v-model="addContactForm.linkUrl"
          ></el-input> </el-form-item
        ><el-form-item label="图片地址" prop="picUrl">
          <el-input
            placeholder="请输入"
            v-model="addContactForm.picUrl"
          ></el-input> </el-form-item
        ><el-form-item label="类型" prop="type">
          <el-input
            placeholder="请输入"
            v-model="addContactForm.type"
          ></el-input>
        </el-form-item
        >
        <el-form-item label="排序" prop="sort">
          <el-input
            placeholder="请输入"
            v-model="addContactForm.sort"
          ></el-input> </el-form-item
        >
        <el-form-item label="是否启用" prop="status">
          <el-select
                v-model="queryForm.status"
                placeholder="请选择"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in dict_examstatus"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            placeholder="请输入"
            v-model="addContactForm.remark"
          ></el-input> </el-form-item
        >
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addContactDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
    </div>
</template>

<script>
import { bannerList, bannerDelete } from '@/http/api'
export default {
  data () {
    return {
      dict_examstatus: this.$userInfo.dictExamstatus(),
      addContactDialogVisible: false,
      addContactForm: {},
      dataList: [],
      addContactFormRules: {
        picUrl: [{ required: true, message: '请输入', trigger: 'blur' }],
        status: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      queryForm: {
      }
    }
  },
  created () {
    this.getAllLogsList()
  },
  methods: {
    getAllLogsList (type) {
      bannerList(this.queryForm).then((res) => {
        this.dataList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    showDeleteAccountTip (id) {
      this.$confirm('是否确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteBanner(id)
      })
    },
    deleteBanner (id) {
      bannerDelete({ id: id }).then((res) => {
        this.getAllLogsList()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    goBack () {
      this.$router.back()
    },
    // 修改轮播图状态
    accountStateChange (data) {
      // disableSchoolTeacher({ id: data.id, isDelete: data.isDelete ? 1 : 0, schoolId: this.editInfoForm.id }).then((res) => {
      //   this.$message.success('状态修改成功')
      //   this.getSchoolTeacherList()
      // }).catch((err) => {
      //   console.log('返回的err', err)
      // })
    }
  }
}
</script>

<style scoped>
.a-btn {
    color: #409eff;
    font-size: 14px;
}
.row {
    display: flex;
    margin-bottom: 12px;
}
</style>
